import { useSoftUIController } from '@/context';
import axios from 'axios';
import L from "leaflet";
import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import useWindowSize from "@/hooks/useWindowSize";
import breakpoints from "@/assets/theme/base/breakpoints"


const EventContext = createContext(null);


export const EventProvider: React.memo = ({ children, events, types, categories, periferiakes_enotites }) => {

  // console.log(events)
  // const [dynamicEvents, setDynamicEvents] = useState(events);
  const [eventData, setEventData] = useState(events?.data)
  const [selectedEvents, setSelectedEvents] = useState(events?.data);
  const [eventsForMap, setEventsForMap] = useState(events?.data);
  const [eventsForSearch,setEventsForSearch] = useState(events?.data);
  const [typesOptions, setTypesOptions] = useState([{ label: "Τύπος Καταλύματος", value: null }, ...types?.data])
  const [categoriesOptions, setCategoriesOptions] = useState([{ label: "Κατηγορία Καταλύματος", value: null }, ...categories?.data])
  const [periferiakesEnotitesOptions, setPeriferiakesEnotitesOptions] = useState([{ label: "Περ. Ενότητα", value: null }, ...periferiakes_enotites?.data])
  const [periferiakiEnotita, setPeriferiakiEnotita] = useState("");
  const [type, setType] = useState("");
  const [category, setCategory] = useState("");
  const [center, setCenter] = useState([39.074208, 21.824312]);
  const [markers, setMarkers] = useState([])
  const [showFilters, setShowFilters] = useState(true);
  const [mapView, setMapView] = useState(null);
  const [fly, setFly] = useState(false);
  const [loading, setLoading] = useState(true);
  const [initLoad,setInitLoad] = useState(true)
  const [open, setOpen] = useState(false);
  const { data } = events
  const [controller, dispatch] = useSoftUIController()
  const { search } = controller
  const queryParams = new URLSearchParams(window.location.search);

  const eventType = queryParams.get('type');
  const citizenType = queryParams.get('citizen');
  const screenWidth = useWindowSize()
  const [isMobile,setIsMobile] = useState(screenWidth < breakpoints.values.sm);

  useEffect(()=>{
    setIsMobile(screenWidth < breakpoints.values.sm)
  },[screenWidth])

  function searchTermFunction(){
    let filteredEvents = []
    //eventsForSearch.map((value,index) => {
    eventsForMap.map((value,index) => {

        let searchTerm = search?.toLowerCase();
        //searchTerm = searchTerm?.normalize("NFD").replace(/[\u0300-\u036f]/g, "");

        let title = value?.title?.toLowerCase();
        title = title?.normalize("NFD").replace(/[\u0300-\u036f]/g, "");

        let desc = value?.description?.toLowerCase();
        desc = desc?.normalize("NFD").replace(/[\u0300-\u036f]/g, "");

        let city = value?.city?.toLowerCase();
        city = city?.normalize("NFD").replace(/[\u0300-\u036f]/g, "");

        let postcode = value?.postcode;
        //postcode = postcode?.normalize("NFD").replace(/[\u0300-\u036f]/g, "");

        if(title?.indexOf(searchTerm) >= 0 || desc?.indexOf(searchTerm) >= 0 || city?.indexOf(searchTerm) >= 0
         || postcode?.indexOf(searchTerm) >= 0){
            filteredEvents.push(value);
        }

        //console.log('name => ', name)
    })



    setSelectedEvents(filteredEvents)
  }

  useEffect(() =>{

    //* Frontend Search
    if(periferiakiEnotita || type || category) {

        /*const filteredEvents = eventsForMap.filter((event) =>event.name.toLowerCase().includes(search));
        setSelectedEvents(filteredEvents)*/
        searchTermFunction()


    }
    //* Backend search
    else {
        if(search.length<3) {

        }

        const timeOutId = setTimeout(() => searchRequest(), 500);
        return () => clearTimeout(timeOutId);
        //searchRequest()

    }
  },[search])

  async function searchRequest() {
    try {
      const response = await axios.get('/', {
        params: {
          eventType: eventType,
          periferiakiEnotita: periferiakiEnotita,
          katalimaType: type,
          category:category,
          search: search,
          citizen:citizenType
        }
      });
      //setSelectedEvents(response.data.events);
      setEventsForMap(response.data.events)
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  //setEVENTS FROM RESPONSE
  useEffect(() => {

    let m: any = [];
    setEventsForSearch(eventsForMap);

    eventsForMap?.map((value: { latitude: any; longitude: any; }, index: any) => {
      m.push({ lat: Number(value.latitude), lng: Number(value.longitude) })
    })
    setMarkers(m)


  }, [eventsForMap])


  //calculateCenter Of markers After set Selected Events From Response
  useEffect(() => {

    let bounds = null
    let allMarks = []
    if (!markers.length) {
      setCenter([39.074208, 21.824312])
    }
    const latLngs = markers.map(position => {
      if (position.lat && position.lng) {
        //return L.latLng(position.lat, position.lng);
        allMarks.push(L.latLng(position.lat, position.lng));

      }

    });

    if (getLatLngBounds()) {
      bounds = new L.LatLngBounds(allMarks); //L.LatLngBounds(polll);
      setCenter([bounds?.getCenter()?.lat, bounds?.getCenter()?.lng])

    }

    checkBounds()
    getTermFromURL()

  }, [markers])

  const checkBounds = () => {

    let evs = [];

    if(!isMobile){


      eventsForMap?.map((event, value) => {

        let eventPos = {
          lat: +event?.latitude ?? 0,
          lng: +event?.longitude ?? 0
        }

        if (mapView) {

          if( mapView?.getBounds()?.contains(eventPos)){
            evs.push(event)

          }
        }
      });
    }else{
      evs = eventsForMap
    }

    //const filteredEvents = evs.filter((event) =>event.title.toLowerCase().includes(search));
    searchTermFunction()
    setSelectedEvents(evs)
    setLoading(false)

  }

  function getTermFromURL() {
    let url = window.location.href
    const searchParams = new URLSearchParams(url.split('?')[1])
    setShowFilters(searchParams.get('type') == 'katalima' || !searchParams.get('type'))

  }


   useEffect(()=>{

     if(mapView && fly && !isMobile){
      setFly(false)
      let zoom = 7
      if(periferiakiEnotita){
        zoom = 10;
      }
      mapView.flyTo(center,zoom)
      //mapView.setZoom(10)
    }
  }, [center])

  const getLatLngBounds = () => {

    let bounds = null
    if (!markers.length) {
      return false
    }

    const latLngs = markers.map(position => {
      if (position.lat && position.lng) {
        return L.latLng(position.lat, position.lng);


      }

    });
    bounds = L.latLngBounds(latLngs);
    return bounds;
  };



  const contextValue = useMemo(() => {
    return {
      eventData, markers, getLatLngBounds, selectedEvents, checkBounds, typesOptions, categoriesOptions, periferiakesEnotitesOptions,
      periferiakiEnotita, setPeriferiakiEnotita, type, category, setType, setCategory, center,
      setSelectedEvents,showFilters,setCenter,setMapView,setMarkers,eventsForMap,setEventsForMap, setFly, mapView,loading, setLoading,initLoad,setInitLoad,
      open, setOpen, isMobile
    }

  }, [initLoad,eventData, markers, selectedEvents, search, periferiakiEnotita, type, category, center,showFilters,setMapView,setMarkers,eventsForMap,mapView,loading,open, isMobile]);

  return (
    <EventContext.Provider value={contextValue}>


      {/* <ContextDevTool context={AppContext} id="app-context" displayName="App" /> */}
      {children}
    </EventContext.Provider>
  );

}

export const useEventContext = () =>

  useContext<{
    eventData: any
    markers: any
    getLatLngBounds: any
    typesOptions: any
    categoriesOptions: any
    periferiakesEnotitesOptions: any
    selectedEvents: any
    checkBounds: any
    type: any
    category: any
    setType: any
    setCategory: any
    periferiakiEnotita: any
    setPeriferiakiEnotita: any
    center: any
    setSelectedEvents:any
    showFilters:any
    setCenter:any
    setMapView:any
    mapView:any
    setMarkers:any
    eventsForMap:any
    setEventsForMap:any
    setFly:any
    loading:any
     setLoading:any
     initLoad:any
     setInitLoad:any,
     open:any
     setOpen:any,
     isMobile:any
  }>(EventContext);

export default EventContext;
